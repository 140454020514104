import { Link } from 'gatsby'
import React from 'react'
import './board.css'

export const Employements = ({ jobs }) => {
  const MAX_LENGTH = 100
  return (
    <div className="box center card employment_card" >
      <div className="box center">
        <div className="container" >
        <h2 
        className="heading"
        style={{
          color: 'black' ,
          fontSize: "28px",
          fontWeight: "600" ,
          fontFamily: "arial",
          marginBottom:"10px"
        }}
      >
         Employment
      </h2>
          <div className="row">
            {jobs.map((job, index) => {
              return (
                <div className="col-md-4" key={index} >
                  <Link to={"/employementdetails"}
                    state={{
                      strapiId: job.strapiId,
                      jobDetails: job
                    }}

                  >

                    <div
                      className="team-player"
                      style={{
                        backgroundColor: 'rgb(249, 249, 255)',
                        minHeight: '430px',
                      }}
                    >

                      <p className="jobField">
                        <span className="jobLable">Position Title:</span>{' '}
                        {job.jp_position_title}
                      </p>
                      <p className="jobField">
                        <span className="jobLable"> Start Date:</span>{' '}
                        {job.jp_start_date}
                      </p>
                      <p className="jobField">
                        <span className="jobLable">Application Deadline:</span>{' '}
                        {job.jp_appl_deadline}
                      </p>
                      <p className="jobField">
                        <span className="jobLable"> Location:</span>{' '}
                        {job.jp_location}
                      </p>
                      <p className="jobField">
                        <span className="jobLable">Position Qualifications:</span>{' '}
                        {`${job.jp_position_qual.substring(0, MAX_LENGTH)}`}
                        {job.jp_position_qual.length > MAX_LENGTH && "..."}
                      </p>



                      <p className="jobField">
                        <span className="jobLable">Description:</span>{' '}

                        {`${job.jp_position_desc.substring(0, MAX_LENGTH)}`}
                        {job.jp_position_desc.length > MAX_LENGTH && "..."}
                      </p>
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
export default Employements
