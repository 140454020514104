import React, {useState, useEffect} from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import Employements from '../components/employements'
import Login from '../pages/login'
import '../assets/main.css';

const Employement = ({ data, location }) => {
  const {
    allStrapiJobs: { nodes: jobs },
  } = data
  

  const [agreed, setAgreed] = useState(false)

  // This runs when the page is loaded.
  useEffect(() => {
    if (localStorage.getItem('isAuthenticated')) {
      setAgreed(true)
    }
  }, [])
  return (
    <Layout location={location} crumbLabel="Employment">
      {' '}
      {!agreed ? (
        <>
          <Login loginModal={agreed} />{' '}
          <div class="card">
                 <div class="center">
               <p style={{paddingTop:"50px",paddingBottom:"50px",fontSize:"25px"}}>Only members can view this page</p>
               </div>
               </div>
        </>
      ) : (
        <div className="box ">
          <Employements jobs={jobs} />{' '}
        </div>
      )}{' '}
    </Layout>
  )
}

export const query = graphql`
  {
    allStrapiJobs(sort:{fields:Jp_Order,order:ASC}) {
      nodes {
        strapiId
        jp_appl_deadline
        jp_contact_email
        jp_contact_name
        jp_contact_other
        jp_contact_phone
        jp_location
        jp_position_desc
        jp_position_qual
        jp_position_title
        jp_contact_fax
        jp_contact_videophone
        jp_start_date(formatString: "")
      }
    }
  }
`

export default Employement
